import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/introduction/"
        }}>{`Introduction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/shortcuts/"
        }}>{`Shortcuts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/serialization/"
        }}>{`Serialization`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/"
        }}>{`Components`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/components/background-gradient"
            }}>{`BackgroundGradient`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/components/text-gradient"
            }}>{`TextGradient`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/components/youtube"
            }}>{`Youtube`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/components/gist"
            }}>{`Gist`}</a></li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      